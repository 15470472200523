

class HorizonScroller {
  constructor(element) {
    this.element = element;
    this.screen = this.element.querySelector('[data-horizon-screen]');
    this.list = this.element.querySelector('[data-horizon-list]');
    this.items = Array.from(this.list.querySelectorAll('[data-horizon-item]'));
    this.status = 'inactive';
    this.sliderWidth = this.items.length * window.innerWidth;
    this.scrolled = 0;

    this.progressElem = this.element.querySelector('[data-horizon-progress]');
    this.progressValuesElem = this.element.querySelector('[data-horizon-values]');
    this.progressValues = Array.from(this.progressValuesElem.querySelectorAll('[data-horizon-value]'));

    this.currentSlide = {
      element: null,
      right: 0,
      percent: 0,
      animateBlock: [],
    };

    this.prevSlide = {
      element: null,
      animateBlock: [],
    };

    this.animateBlock = [];
    this.items.forEach((item) => {
      let animateBlocks = item.querySelectorAll('[data-horizon-animate]');
      if (animateBlocks.length) {
        this.animateBlock.push({
          id: item.dataset.horizonItem,
          items: Array.from(animateBlocks),
        });
      }
    });


    this.init();
  }

  init() {
    this.elementModify();
    this.bind();
  }

  elementModify() {
    this.elementHeight = (this.items.length - 1) * window.innerWidth + window.innerHeight;
    this.element.style.height = `${this.elementHeight}px`;
    this.element.style.position = 'relative';
  }

  bind() {
    this.bindOnScroll();
  }

  bindOnScroll() {
    window.addEventListener('scroll', (e) => {
      this.positionObject = this.getPositionObject();

      switch (this.status) {
        case 'inactive':
          this.inactiveHandle();
          break;
        case 'active':
          this.activeHandle();
          break;
        default: break;
      }
    });
  }

  inactiveHandle() {
    if (this.positionObject.topElementIsHigher && !this.positionObject.bottomElementIsHigher) {
      this.setActive();
    }
  }

  activeHandle() {
    /*
      T = topElementIsHigher
      B = bottomElementIsHigher
      condition = (!T && !B) || T && B => T === B
     */
    if (this.positionObject.topElementIsHigher === this.positionObject.bottomElementIsHigher) {
      this.setInactive(!this.positionObject.topElementIsHigher);
      return;
    }

    this.setScrolled();
    this.progressHandle();
    this.animateHandle();
  }

  animateHandle() {
    if (this.currentSlide.animateBlock) {
      this.animateGroup(this.currentSlide.animateBlock.items, true);
    }

    if (this.prevSlide.animateBlock) {
      this.animateGroup(this.prevSlide.animateBlock.items, false);
    }
  }

  animateGroup(items, isCurrent) {
    items.forEach((item) => {
      switch (item.dataset.horizonAnimate) {
        case 'rotate': {
          const percent = isCurrent ? this.currentSlide.percent : (100 + this.currentSlide.percent);
          let deg = parseInt(item.dataset.horizonRotate, 10);
          deg = deg * percent / 100;
          item.style.transform = `rotate(${deg}deg)`;
          break;
        }
        case 'to-left': {
          let moveX = parseInt(item.dataset.horizonX, 10);
          let kf = 0;
          if (isCurrent) {
            kf = (100 - this.currentSlide.percent) / 100;
          } else {
            kf = this.currentSlide.percent / -100;
          }
          moveX = moveX * kf;
          item.style.transform = `translateX(${moveX}px)`;
          break;
        }
        case 'to-right': {
          let moveX = parseInt(item.dataset.horizonX, 10);
          let kf = 0;
          if (isCurrent) {
            kf = (100 - this.currentSlide.percent) / -100;
          } else {
            kf = this.currentSlide.percent / 100;
          }
          moveX = moveX * kf;
          item.style.transform = `translateX(${moveX}px)`;
          break;
        }
      }
    });
  }

  progressHandle() {
    const currentSlide = this.getCurrentSlide();
    if (currentSlide !== this.currentSlide.element) {
      this.changeSlide(currentSlide);
    }

    this.setProgressBar();
  }

  setProgressBar() {
    let width = 0;
    let number = 0;

    while (this.progressValues[number] !== this.currentProgress) {
      width += this.progressValues[number].getBoundingClientRect().width;
      number++;
    }

    const preBound = this.progressValues[number - 1].getBoundingClientRect();
    width -= preBound.width;
    width += this.currentSlide.percent / 100 * preBound.width;
    this.progressElem.style.width = `${width}px`;
  }

  changeSlide(currentSlide) {
    this.currentSlide.element = currentSlide;
    this.currentSlide.animateBlock = this.animateBlock.find((item) => item.id === currentSlide.dataset.horizonItem);
    this.currentProgress = this.progressValues.find((item) => item.dataset.horizonValue === this.currentSlide.element.dataset.horizonItem);

    const prev = currentSlide.previousElementSibling;
    if (prev) {
      this.prevSlide.element = prev;
      this.prevSlide.animateBlock = this.animateBlock.find((item) => item.id === prev.dataset.horizonItem);
    }
  }

  getCurrentSlide() {
    return this.items.find((item) => {
      let right = item.getBoundingClientRect().right;
      if (right >= window.innerWidth && right <= window.innerWidth * 2) {
        this.currentSlide.right = right;
        this.currentSlide.percent = 100 - ((right - window.innerWidth) / window.innerWidth * 100);
        return true;
      }

      return false;
    });
  }

  setScrolled() {
    this.scrolled = this.positionObject.scrollBottom - this.positionObject.offsetTop - window.innerHeight;
    this.setTranslate();
  }

  setTranslate(translate) {
    if (translate === undefined) {
      translate = this.scrolled;
    }
    this.list.style.transform = `translate3d(-${translate}px, 0, 0)`;
  }

  getPositionObject() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollBottom = scrollTop + window.innerHeight;

    const bounds = this.element.getBoundingClientRect();
    const offsetTop = bounds.top + scrollTop;
    const offsetBottom = offsetTop + bounds.height;

    return {
      scrollTop,
      scrollBottom,
      offsetTop,
      offsetBottom,
      height: bounds.height,
      // Верхняя граница элемента выше чем верхняя граница экрана?
      topElementIsHigher: scrollTop > offsetTop,
      // Нижняя граница элемента ниже чем верхняя граница экрана?
      bottomElementIsHigher: scrollBottom > offsetBottom,
    };
  }

  setActive() {
    this.status = 'active';
    this.screen.style.position = 'fixed';
    this.screen.style.bottom = 0;
    this.screen.style.top = 0;
  }

  setInactive(isTop) {
    this.status = 'inactive';
    this.screen.style.position = 'absolute';

    if (isTop) {
      this.screen.style.bottom = '';
      this.setTranslate(0);
    } else {
      this.screen.style.top = '';
      this.setTranslate((this.items.length - 1) * window.innerWidth);
    }
  }
}
function initHorizonScroller() {
  const horizonElement = document.querySelector('[data-horizon]');
  if (horizonElement) {
    setTimeout(() => {
      if (window.innerWidth >= 1200) {
        const horizon = new HorizonScroller(horizonElement);
      }
    }, 2000);
  }
}

document.addEventListener('DOMContentLoaded', initHorizonScroller);