import { tns } from 'tiny-slider/src/tiny-slider';

class DoubleSlider {
  constructor(navigationOptions, infoOptions) {
    this.navigationSlider =  tns(navigationOptions);
    this.infoSlider =  tns(infoOptions);
    this.init();
  }

  init() {
    this.bindNavigation();
    this.bindArrow();
  }

  bindNavigation() {
    const info = this.navigationSlider.getInfo();
    const container = info.container;
    info.container.querySelectorAll('[data-protected-select]').forEach((item) => {
      item.addEventListener('click', (e) => {
        e.preventDefault();
        const currentActive = container.querySelector('[data-protected-select]._active');
        if (currentActive) {
          currentActive.classList.remove('_active');
        }
        item.classList.add('_active');
        const index = item.dataset.protectedSelect;
        this.navigationSlider.goTo(index);
        this.infoSlider.goTo(index);
      });
    });
  }

  bindArrow() {
    const sliderContainer = this.navigationSlider.getInfo().container.closest('[data-protected-slider-container]');
    const prevArrow = sliderContainer.querySelector('[data-arrow-left]');
    prevArrow.addEventListener('click', () => {
      this.navigationSlider.goTo('prev');
    });

    const nextArrow = sliderContainer.querySelector('[data-arrow-right]');
    nextArrow.addEventListener('click', () => {
      this.navigationSlider.goTo('next');
    });
  }
}

function startDoubleSlider() {
  const navigationElement = document.querySelector('[data-protected-navigation-slider]');
  const infoElement = document.querySelector('[data-protected-info-slider]');

  if (navigationElement && infoElement) {
    const navigationsOptions = {
      container: navigationElement,
      autoWidth: true,
      loop: false,
      controls: false,
      nav: false,
    };
    const infoOptions = {
      container: infoElement,
      slideBy: 1,
      loop: false,
      controls: false,
      nav: false,
      touch: false,
      mode: 'gallery',
      autoHeight: false,
    };
    const protectedSlider = new DoubleSlider(navigationsOptions, infoOptions);
  }
}

document.addEventListener('DOMContentLoaded', startDoubleSlider);