function moveImage() {
  window.addEventListener('scroll', () => {
    const image = document.querySelector('[data-dealer-image]');
    const tomat = document.querySelector('[data-tomat-image]');
    const materialImage = document.querySelector('[data-main-mat]');
    if (image) {
      window.addEventListener('scroll', () => {
        if (window.pageYOffset > 200 && !image.classList.contains('_move')) {
          image.classList.add('_move');
        }
      });
    }
    if (tomat) {
      window.addEventListener('scroll', () => {
        if (window.pageYOffset > 1000) {
          tomat.classList.add('_change');
          materialImage.classList.add('_change');
        }
      });
    }
  });
}

window.addEventListener('DOMContentLoaded', moveImage);