class OfferSlider {
  constructor(sliderElement) {
    const buttonItems = sliderElement.querySelectorAll('[data-offer-button-item]');
    const textItems = sliderElement.querySelectorAll('[data-offer-text-item]');
    const backgroundItems = sliderElement.querySelectorAll('[data-offer-back-item]');

    if (!buttonItems.length) {
      throw Error('OfferSlider not found element');
    }
    if (buttonItems.length !== textItems.length || buttonItems.length !== backgroundItems.length) {
      throw Error('OfferSlider count element error');
    }

    this.slides = [];

    buttonItems.forEach((buttonItem) => {
      const itemId = buttonItem.dataset.offerButtonItem;
      if (!itemId) {
        throw Error('OfferSlider: no item id');
      }
      const slide = {
        id: itemId,
        button: buttonItem,
        text: Array.from(textItems).find(item => item.dataset.offerTextItem === itemId),
        background: Array.from(backgroundItems).find(item => item.dataset.offerBackItem === itemId),
      };

      if (!slide.text || !slide.background) {
        throw Error('OfferSlider: key element error');
      }

      this.slides.push(slide);
    });

    this.init();
  }

  init() {
    this.createOrder();
    this.slides.forEach(slide => this.bindButton(slide));
  }

  createOrder() {
    let firstSlide = null;
    let lastSlide = null;

    this.slides.sort((a, b) => {
      if (a.id === b.id) {
        throw Error('offerSlider equal ids');
      }
      return a.id > b.id ? 1 : -1;
    }).forEach((slide) => {
      if (lastSlide === null) {
        firstSlide = slide;
      } else {
        slide.prev = lastSlide.id;
        lastSlide.next = slide.id;
      }
      lastSlide = slide;
    });

    lastSlide.next = firstSlide.id;
    firstSlide.prev = lastSlide.id;
  }


  bindButton(slide) {
    slide.button.addEventListener('click', (e) => {
      e.preventDefault();
      this.slides.forEach((iterationSlide) => {
        if (iterationSlide.id === slide.next || iterationSlide.id === slide.prev) {
          iterationSlide.button.classList.add('_active');
        } else {
          iterationSlide.button.classList.remove('_active');
        }
      });

      this.slides.filter(findedSlide => findedSlide.background.classList.contains('_active')).forEach((activeSlide) => {
        activeSlide.background.classList.add('_fade-out');
        activeSlide.background.classList.remove('_active');
        setTimeout(() => activeSlide.background.classList.remove('_fade-out'), 1000);
      });

      this.slides.filter(findedSlide => findedSlide.text.classList.contains('_active')).forEach(activeSlide => activeSlide.text.classList.remove('_active'));

      slide.text.classList.add('_active');
      slide.background.classList.add('_active');
    });
  }

  getSlide(id) {
    const findedSlide = this.slides.find(slide => slide.id === id);
    if (findedSlide) {
      return findedSlide;
    }

    throw Error('OfferSlider: not found slide');
  }


}
function initOffers() {
  const sliderElement = document.querySelector('[data-offer-slider]');
  if (sliderElement) {
    try {
      const offerSlider = new OfferSlider(sliderElement);
    } catch (e) {
      console.log(e);
    }
  }
}

document.addEventListener('DOMContentLoaded', initOffers);