function initActive() {
  const items = document.querySelectorAll('[data-need-active]');
  const infos = document.querySelectorAll('[data-show-info]');
  items.forEach((item) => {
    if (item) {
      item.addEventListener('click', (e) => {
        if (!item.classList.contains('_active')) {
          item.classList.add('_active');
        } else {
          item.classList.remove('_active');
        }
      });
    }
  });
  infos.forEach((info) => {
    info.addEventListener('click', () => {
      const shows = document.querySelectorAll('[data-show]');
      shows.forEach((show) => {
        if (!show.classList.contains('_active')) {
          show.classList.add('_active');
          info.classList.add('_hide');
        } else {
          show.classList.remove('_active');
          info.classList.remove('_hide');
        }
      });
    });
  });
}
document.addEventListener('DOMContentLoaded', initActive);