import {tns} from 'tiny-slider/src/tiny-slider';
import "../../node_modules/tiny-slider/src/tiny-slider.scss";

const interactSlider = (slider) => {
  const stopAutoplay = () => {
    slider.pause();
  };

  const startAutoplay = () => {
    slider.play();
  };

  slider.events.on('dragStart', stopAutoplay);
  slider.events.on('touchstart', stopAutoplay);
  slider.events.on('dragEnd', startAutoplay);
  slider.events.on('touchEnd', startAutoplay);
};

const bindArrows = (left, right, slider) => {
  const leftArrows = document.querySelectorAll(left);
  const rightArrows = document.querySelectorAll(right);

  leftArrows.forEach((leftArrow) => {
    leftArrow.addEventListener('click', () => {
      slider.pause();
      slider.goTo('prev');
      slider.play();
    });
  });

  rightArrows.forEach((rightArrow) => {
    rightArrow.addEventListener('click', () => {
      slider.pause();
      slider.goTo('next');
      slider.play();
    });
  });

  interactSlider(slider);
};

if (document.querySelector('[data-about-slider]')) {
  const slider = tns({
    container: '[data-about-slider]',
    items: 1,
    slideBy: 1,
    nav: true,
    speed: 700,
    autoplay: true,
    controlsContainer: '#customize-controls',
    navContainer: '#customize-controls',
    navAsThumbnails: true,
    controls: false,
    loop: true,
    swipeAngle: true,
    rewind: false,
    autoplayButtonOutput: false,
    mode: 'gallery',
    animateIn: 'fadeIn',
    animateOut: 'fadeOut',
  });

  bindArrows('[data-offer-left]', '[data-offer-right]', slider);
}

if (document.querySelector('[data-index-slider]')) {
  const sliderIndex = tns({
    container: '[data-index-slider]',
    items: 1,
    slideBy: 1,
    nav: true,
    speed: 700,
    controlsContainer: '#customize-control',
    navContainer: '#customize-control',
    navAsThumbnails: true,
    controls: false,
    loop: true,
    swipeAngle: true,
    rewind: false,
    autoplayButtonOutput: false,
    mode: 'gallery',
    animateIn: 'fadeIn',
    animateOut: 'fadeOut',
  });

  bindArrows('[data-offer-left]', '[data-offer-right]', sliderIndex);
}

if (document.querySelector('[data-sal-slider]')) {
  const sliderSal = tns({
    container: '[data-sal-slider]',
    items: 1,
    slideBy: 1,
    nav: true,
    speed: 700,
    controlsContainer: '#customize-sal',
    navContainer: '#customize-sal',
    navAsThumbnails: true,
    controls: false,
    loop: true,
    swipeAngle: true,
    rewind: false,
    autoplayButtonOutput: false,
    mode: 'gallery',
    animateIn: 'fadeIn',
    animateOut: 'fadeOut',
  });

  bindArrows('[data-offer-left]', '[data-offer-right]', sliderSal);
}