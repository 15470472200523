require('../scss/app.scss');
require('./svg/svg.js');

window.noZensmooth = true;

require('./common/true-vh');
require('./common/goal');
require('./common/js-validation');
require('./common/links');
require('./common/main');
require('./common/pagination');
require('./common/respond');
require('./common/map');
require('./common/need-animation');

require('./field/phone.js');
require('../components/smart-tabs/smart-tabs');
require('../vue/app.js');
require('./sliders/offer');
require('./sliders/horizontScroller');
require('./protected');
require('./hamburger');
require('./addActive');
require('./sliders');
require('./scroll');
require('fslightbox');